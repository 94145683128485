<template>
  <div class="actions">
    <v-btn
      class="actions__remove-connection"
      v-if="isConnected"
      @click.native="$emit('remove-connection')"
      icon
      x-large
      color="primary"
    >
      <v-icon>mdi-account-minus-outline</v-icon>
    </v-btn>

    <v-btn
      class="actions__add-connection"
      v-else
      icon
      x-large
      color="primary"
      @click.native="$emit('add-connection')"
    >
      <v-icon>mdi-account-plus-outline</v-icon>
    </v-btn>

    <v-btn
      class="actions__chat"
      icon
      x-large
      color="primary"
      @click.native="$emit('chat', userProfile)"
    >
      <v-icon>mdi-chat-outline</v-icon>
    </v-btn>

    <v-btn
      class="actions__calendar"
      icon
      x-large
      color="primary"
      @click.native="$emit('calendar')"
      v-if="showCalendar"
    >
      <v-icon>mdi-calendar</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'ProfileActions',
  props: {
    userProfile: Object,
    isConnected: Boolean,
    showCalendar: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
